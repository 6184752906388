<template>
  <div class="department-gallery">
    <v-container>
      <v-row v-if="objGallery.length > 1">
        <v-col
          v-for="(img, index) in objGallery"
          :key="index"
          class="d-flex child-flex"
          :cols="colsCount(objGallery)"
        >
          <v-img
            :src="apiAddress + img.item"
            alt=""
            aspect-ratio="1"
            class="grey lighten-2"
            @click="stateModal(true, apiAddress + img.item)"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row v-if="objGallery.length === 1">
        <v-col cols="12" class="d-flex justify-center">
          <v-img
            :src="apiAddress + objGallery[0].item"
            alt=""
            aspect-ratio="1"
            class="grey lighten-2 lonely-image"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-row v-if="objGallery.length === 0">
        <v-col class="d-flex justify-center">
          <h3>Галерея пуста</h3>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="stateModal(false, null)"
    >
      <v-card>
        <v-img
          :src="this.currentImage"
          alt=""
          aspect-ratio="1"
          class="grey lighten-2"
        >
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiArrowRightThick,
  mdiArrowLeftThick,
  mdiCheckboxBlankCircle,
} from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
export default {
  name: "DepartmentCardGallery",
  data: () => ({
    dialog: false,
    currentImage: "",
    icons: {
      mdiArrowRightThick,
      mdiArrowLeftThick,
      mdiCheckboxBlankCircle,
    },
  }),
  computed: {
    apiAddress() {
      return names.API_ADDRESS;
    },
    colsCount() {
      return function (array) {
        if (array.length > 4) {
          return 4;
        }
        if (array.length < 4) {
          return array.length + 1;
        }
      };
    },

    ...mapGetters({
      objGallery: names.DEPARTMENT_GALLERY,
      depId: names.DEPARTMENT_ID_CARD,
    }),
  },
  created() {
    this.getGallery({ departmentId: this.depId });
  },

  methods: {
    ...mapActions({
      getGallery: names.ACTION_GET_DEPARTMENT_GALLERY,
    }),
    stateModal(state, currentImage) {
      this.dialog = state;
      this.currentImage = currentImage;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.department-gallery {
  display: flex;
  width: 100%;
  min-height: 500px;
  margin: auto 0;
  padding: 60px 0;
  justify-content: center;
  background-color: #ececec9c;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .lonely-image {
    max-width: 500px;
  }
}
</style>
